import { createWebHistory, createRouter } from 'vue-router'
import { useDefaultStore } from './store'

// Import views
import Cams from '@/views/Cams.vue'
import CamList from '@/views/Cams/CamList.vue'
import CamView from '@/views/Cams/CamView.vue'
import CamArchive from '@/views/Cams/CamArchive.vue'
import Favorite from '@/views/Cams/Favorite.vue'
import CamArchiveNew from '@/views/Cams/CamArchiveNew.vue'
import CamListNew from '@/views/Cams/CamListNew.vue'
import CamViewNew from '@/views/Cams/CamViewNew.vue'
import TimelineNew from '@/components/cam/TimelineNew.vue'
import Auth from '@/views/Auth.vue'
import Login from '@/views/Auth/Login.vue'
import Admin from '@/views/Admin.vue'
import Editor from '@/views/Admin/Editor.vue'
import Error404 from '@/views/Error404.vue'
import ServerStatusPage from '@/views/Admin/ServerStatusPage.vue'

const routes = [
  {
    path: '/',
    component: Cams,
    children: [
      { path: 'testplayer', name: 'player-test', component: TimelineNew },
      { path: 'cam-list/:division?', name: 'cam-list', component: CamList },
      { path: 'cam-listNew/:division?', name: 'cam-listNew', component: CamListNew },
      { path: 'cam/:cam?/:division?', name: 'cam', component: CamView },
      { path: 'cam-new/:cam?/:division?', name: 'cam-new', component: CamViewNew },
      { path: 'cam-archive/:cam?/:division?', name: 'cam-archive', component: CamArchive },
      { path: 'cam-archive-new/:cam?/:division?', name: 'cam-archive-new', component: CamArchiveNew },
      { path: 'cam-archive-all/:division?/:all', name: 'cam-archive-all-cams', component: CamArchive },
      { path: 'favorite', name: 'cam-favorites', component: Favorite },
    ].map(route => ({ ...route, meta: { transition: 'fade-in-down' } }))
  },
  {
    path: '/auth',
    component: Auth,
    children: [
      { path: 'login', name: 'login', component: Login, meta: { transition: 'fade-in-down' } }
    ]
  },
  {
    path: '/admin',
    component: Admin,
    children: [
      { path: 'server-info', name: 'server-info', component: ServerStatusPage, meta: { transition: 'fade-in-down', admin: true } },
      { path: 'editor', name: 'editor', component: Editor, meta: { transition: 'fade-in-down', admin: true } }
    ]
  },
  { path: '/404', name: '404', component: Error404 },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

const originalPush = router.push
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => console.error(err))
}

router.beforeEach(async (to, from, next) => {
  const store = useDefaultStore()
  const { name, params } = to

  if (['cam-list', 'cam', 'cam-archive', 'cam-archive-new'].includes(name) && params.division) {
    store.currentDivision = store.allDivisions.find(el => el.guid === params.division)
  }
  
  if (name === 'cam-list' && !params.division) {
    const division = store.allDivisions.sort((a, b) => a.name.localeCompare(b.name))[0]
    if (division) {
      store.currentDivision = division
      return next({ name: 'cam-list', params: { division: division.guid } })
    }
  }

  if (name !== 'login' && !localStorage.token) return next({ name: 'login' })
  next()
})
