<template>
  <div class="player-wrapper">
    <div data-vjs-player>
      <video class="video-js vjs-default-skin" ref="video"></video>
      <div
        v-if="playerMounted"
        @click.self="clickCam"
        class="custom-ui"
      >
        <div v-if="showControls || showControlsForced" class="controls">
          <span class="cam-name">{{ camName }}</span>
          <button class="live">
            <span class="livetext">LIVE</span>
          </button>
          <button
            @click.self="toggleFavorite"
            class="mdi mdi-star favorite"
            :class="{ gold: isFavorite }"
          ></button>
        </div>
        <span @click.self="clickCam" v-if="store.smallScreen && paused" class="playbig mdi mdi-play">

        </span>
        <span v-if="showTimer" class="time">{{ time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import videojs from 'video.js'
import { eventsMap, events } from './events'
import moment from 'moment'
import { useDefaultStore } from '@/store'
export default {
  name: 'CamPlayerLivePreview',

  setup() {
    const player = shallowRef({})
    const store = useDefaultStore()
    return { player, store }
  },

  emits: ['toggleFavorite', 'clickCam'],
  props: {
    source: {
      type: String,
      required: true
    },
    showControlsForced: {
      type: Boolean,
      default: false
    },
    showTimer: {
      type: Boolean,
      default: true
    },
    isFavorite: {
      type: Boolean,
      default: false
    },
    camName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      timestamp: null,
      playerMounted: false,
      paused: true,
      dotColor: '#EEEEEE',
      showControls: false,
      totalPlayedTime: 0,
      error: false,
      timeSinceLastSegment: 0,
      video: null,
      videoWidth: 0,
      videoHeight: 0
    }
  },
  computed: {
    time() {
      if (this.timestamp) {
        return moment(this.timestamp)
          .add(this.timeSinceLastSegment, 'seconds')
          .format('DD.MM.YYYY, HH:mm:ss')
      } else {
        return ''
      }
    },
    aspectRatio() {
      if (!this.videoWidth || !this.videoHeight) {
        return 16 / 9
      }
      return this.videoWidth / this.videoHeight
    }
  },
  mounted() {
    const video = this.$refs.video
    const options = {
      autoplay: true,
      controls: false,
      muted: true,
      preload: 'metadata',
      controlBar: {
        pictureInPictureToggle: false,
        bigPlayButton: false
      },
      html5: {
        vhs: {
          overrideNative: true,
          handlePartialData: true
        }
      },
      userActions: {
        doubleClick: false
      },
      fluid: true,
      sources: [
        {
          src: this.source,
          type: 'application/x-mpegURL'
        }
      ]
    }
    this.player.value = videojs(video, options, this.playerReady)
  },
  unmounted() {
    events.forEach((eventName) => {
        const eventFunc = eventsMap[eventName]
        if (eventFunc in this) {
          this.player.value.off(eventName, this[eventFunc])
        }
      })
    this.player.value.dispose();
  },
  watch: {
    source: function (to) {
      this.player.value.src({ src: to, type: 'application/x-mpegURL' })
    }
  },
  methods: {
    playerReady() {
      events.forEach((eventName) => {
        const eventFunc = eventsMap[eventName]
        if (eventFunc in this) {
          this.player.value.on(eventName, this[eventFunc])
        }
      })
      this.handleTimeUpdate()
      this.video = this.player.value.el().querySelector('video')
      this.playerMounted = true
    },
    onResize() {
      this.videoHeight = this.video.videoHeight
      this.videoWidth = this.video.videoWidth
    },
    toggleFullScreen() {
      if (this.player.value.isFullscreen()) {
        this.player.value.exitFullscreen()
      } else {
        this.player.value.requestFullscreen()
      }
    },
    onError() {
      this.error = true
      setTimeout(() => {
          this.player.value.reset()
          this.player.value.src({ src: this.source, type: 'application/x-mpegURL' })
          this.player.value.play().catch(err => {
            console.error('Retry Playback Error:', err)
          })
          this.error = false
        }, 3000)
    },
    toggleFavorite() {
      this.$emit('toggleFavorite', this.camName)
    },
    clickCam() {
      if (this.store.smallScreen && this.paused) {
        this.player.value.play();
        return;
      }
      this.$emit('clickCam');
    },
    onUserActive() {
      this.showControls = true
    },
    onFullscreenChange() {
      if (this.player.value.isFullscreen()) {
        this.player.value.exitFullscreen();
        this.$emit("clickCam");
      }
    },
    onUserInactive() {
      this.showControls = false
    },
    onDurationChange() {
      if (this.store.smallScreen) {
        setTimeout(() => {
          this.player.value.pause()
        }, 1000)
      }
    },
    handleTimeUpdate() {
      const tracks = this.player.value.textTracks()
      const vm = this
      let segmentMetadataTrack
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].label === 'segment-metadata') {
          segmentMetadataTrack = tracks[i]
        }
      }
      if (segmentMetadataTrack) {
        segmentMetadataTrack.on('cuechange', function () {
          let activeCue = segmentMetadataTrack.activeCues[0]
          if (activeCue) {
            const extractedDate = activeCue.value.uri
              .split('/')
              .pop()
              .split('-')
              .pop()
              .split('.')[0]
            let date = moment(extractedDate, 'YYYYMMDDHHmmss')

            if (extractedDate.length < 14) {
              date = moment()
            }
            vm.totalPlayedTime = vm.player.value.currentTime()
            vm.timeSinceLastSegment = 0
            vm.timestamp = date
          }
        })
      }
    },
    onTimeUpdate() {
      this.timeSinceLastSegment = Math.floor(this.player.value.currentTime() - this.totalPlayedTime)
    },
    togglePlay() {
      if (this.paused) {
        this.player.value.play()
      } else {
        this.player.value.pause()
      }
    },
    onPlay() {
      this.paused = false
    },
    onWaiting() {
      this.dotColor = '#EEEEEE'
    },
    onSeeking() {
      this.dotColor = '#EEEEEE'
    },
    onPlaying() {
      this.dotColor = '#FF0000'
    },
    onPause() {
      this.paused = true
      this.dotColor = '#EEEEEE';
    }
  }
}
</script>
<style scoped>
.player-wrapper {
  width: 100%;
  height: 100%;
}
.custom-ui {
  position: absolute;
  top: 0;
  width: 100%;
  display: block;
  height: 100%;
  user-select: none;
}
.controls {
  position: absolute;
  bottom: 0px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  width: 100%;
  display: flex;
}

.fullscreen {
  margin-left: auto;
}

.live {
  padding: 0;
  display: flex;
}

.live::before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: v-bind(dotColor);
  margin: auto;
}
.livetext {
  color: white;
  margin: auto 6px;
}
.cam-name {
  color: white;
  margin: auto 16px;
  margin-right: 6px;
  font-size: 14px;
  text-shadow: black 1px 1px 3px;
}
.mdi {
  font-size: 18px;
  color: white;
}
.time {
  position: absolute;
  top: 3%;
  right: 3%;
  color: white;
  font-size: 12px;
  text-shadow: black 1px 1px 3px;
}
.vjs-fullscreen .time {
  font-size: 18px;
}

.video-js {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
}
.gold {
  color: #f2c94c;
}

.playbig {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 150px;
  color: white;
  /* text-shadow: black 1px 1px 3px; */
}
</style>
