<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center card-head">
        <div class="d-flex flex-column">
           <span class="server-name">{{ serverName }}</span>
           <span class="server-version">v{{ serverVersion }}</span>
        </div>
            <span class="dot" :style="{ 'background-color': dot }"></span>
      </div>
      <div v-if="error" class="text-danger">{{ error }}</div>
      <div v-else>
        <div v-if="loading" class="loader d-flex justify-content-center align-items-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div>
          <div v-for="drive in drives" :key="drive.diskName">
            <div className="d-flex justify-content-between align-items-center">
              <span>{{ drive.diskName }}</span>
              <span
                >{{ drive.capacityUsed }}/{{ drive.capacity }} GB {{ drive.daysWritten }}дн</span
              >
            </div>
            <CProgress :height="3" class="m-0">
              <CProgressBar
                :value="(drive.capacityUsed / drive.capacity) * 100"
                :color="getColor((drive.capacityUsed / drive.capacity) * 100)"
              />
            </CProgress>
          </div>
          <div v-if="ramTotal > 0" className="d-flex justify-content-between align-items-center">
            <span>RAM:</span>
            <span>{{ ramUsed }}/{{ ramTotal }}GB</span>
          </div>
          <CProgress v-if="ramTotal > 0" :height="3" class="m-0">
            <CProgressBar
              :value="(ramUsed / ramTotal) * 100"
              :color="getColor((ramUsed / ramTotal) * 100)"
            />
          </CProgress>
          <div
            v-if="updaterVersion != '0.0.0'"
            class="d-flex justify-content-between align-items-center"
          >
            <span>Менеджер обновлений</span>
            <span>{{ updaterVersion }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CProgress, CProgressBar } from '@coreui/bootstrap-vue'
import { checkVersion } from '@/helpers/utils'
export default {
  name: 'ServerInfo',
  components: { CProgress, CProgressBar },
  props: {
    server: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      serverName: this.server.name,
      serverVersion: this.server.version,
      ffmpegProcesses: 0,
      drives: [],
      ramTotal: 0,
      error: null,
      ramFree: 0,
      dot: 'gray',
      updaterVersion: '0.0.0',
      loading: false,
      interval: null
    }
  },
  computed: {
    ramUsed() {
      return this.ramTotal - this.ramFree
    }
  },
  async mounted() {
    if (checkVersion(this.serverVersion, '1.0.0.81')) {
        this.getData();
        this.interval = setInterval(this.getData, 10000);
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    async getData() {
        const protocol = window.location.protocol
      const url = protocol == 'https:' ? this.server.url : this.server.address
      this.loading = true
      try {
        const response = await fetch(`${protocol}//${url}/api/ping`)
        const resp = await response.json()
        this.loading = false
        if (resp.error) {
          this.error = resp.error
          this.dot = 'red'
          return
        }
        const data = resp.data
        this.dot = 'green'
        this.version = data.version
        this.drives = data.driveInfo
        this.ramTotal = Math.round(data.totalRam / 1024 / 1024 / 1024)
        this.ramFree = Math.round(data.freeRam / 1024 / 1024 / 1024)
        this.updaterVersion = data.updaterVersion
      } catch (error) {
        this.error = error.message
        this.dot = 'red'
      }
    },
    getColor(value) {
      if (value < 50) {
        return 'success'
      } else if (value < 75) {
        return 'warning'
      } else {
        return 'danger'
      }
    }   
  }
}
</script>

<style scoped>
.card {
  font-size: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #272c33;
  color: #ececec;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}
.card-head {
    position: relative;
}
.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.dot {
  width: 5px;
  display: block;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.server-name {
    font-size: 12px;
    font-weight: bold;
}
.server-version {
    font-size: 10px;
}
</style>
