<template>
  <div
    :class="{
      'small-screen': smallScreen
    }"
  >
    <div>
      <RouterView />
    </div>
    <Modal />
    <portal-target name="bodyEnd" />
  </div>
</template>

<script lang="js">
import { init, getSettingValue } from './services/local-settings'
import { ApiSys } from '@/services/api'
import { useDefaultStore } from '@/store'
import { loadDivisions } from '@/services/divisions'
import { Favorites } from './services/favorites'
import User from '@/services/user'
import Modal from '@/components/Modal.vue'
import { provide } from 'vue'
import { useModal } from '@/services/modal-store'
export default {
  components: {
    Modal
  },

  data() {
    return {
      smallScreen: false,
      updateTimeout: null,
      refetchTime: 30000
    }
  },
  setup() {
    const store = useDefaultStore()
    const modal = useModal()
    provide('modal', modal)
    return {
      store
    }
  },
  computed: {
    isLoader() {
      return this.store.loader
    }
  },

  async mounted() {
    this.getWindowWidth()
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      this.updateTimeout = setInterval(() => {
        loadDivisions()
      }, this.refetchTime)
    })

    await this.setServerVersion()
    await this.getFavorites()

    this.intervalCall()
  },
  beforeUnmount() {
    clearInterval(this.updateTimeout)
  },

  async created() {
    init()
    await User.getCurrentUser()
    this.store.setGridCam(getSettingValue('interface.gridCam'))
    this.store.setShowCamName(getSettingValue('interface.showCamName'))
    this.store.setShowTimer(getSettingValue('interface.showTimer'))
    this.store.setHideSidebar(getSettingValue('interface.hideSidebar'))
    this.store.camOrder = getSettingValue('interface.camOrder2')
    window.route = this.$route
    window.router = this.$router
    setTimeout(() => {
      //this.store.toggleLoader(false);
    }, 3000)
  },

  methods: {
    getWindowWidth() {
      const width = window.innerWidth

      this.smallScreen = width < 700

      this.store.setSmallScreen(this.smallScreen)
      if (this.smallScreen) this.store.setHideSidebar(true)
    },

    async setServerVersion() {
      try {
        let result = await ApiSys.getSysInfo()

        if (!result.error) {
          this.store.setServerVersion(result.version)
        }
      } catch (e) {
        console.dir(e)
      }
    },

    async getFavorites() {
      try {
        await Favorites.init()
      } catch (e) {
        console.dir(e)
      }
    },

    /**
     * Используется, тк бек в ответ на вызов апи проверяет количесво активных учеток и выполняет разлогин, если их много
     * https://vks2021.myjetbrains.com/youtrack/issue/VKSPROJECT-15/Ogranichenie-raboty-pod-odnoi-uchetkoi
     *
     * @returns {Promise<void>}
     */
    async someApiCall() {
      await ApiSys.getCurrentUserInfo()
    },

    intervalCall() {
      setInterval(() => {
        this.someApiCall()
      }, 30000)
    }
  }
}
</script>

<style lang="stylus"></style>
