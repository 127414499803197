<template>
  <div class="main">
    <div class="container">
      <div class="middle">

        <div class="logo">
          <div class="logo_img"></div>
          <!-- <span class="mdi mdi-camcorder"></span> TVC-->
        </div>

        <div id="login">
          <form @submit.prevent="login">
            <fieldset class="clearfix">
              <p>
                <span class="mdi mdi-account"></span>
                <label for="user" style="display: none"></label>
                <input id="user" v-model="user" type="text" Placeholder="Логин" required>
              </p>

              <p>
                <span class="mdi mdi-form-textbox-password"></span>
                <label for="password" style="display: none"></label>
                <input id="password" v-model="password" type="password" Placeholder="Пароль" required>
              </p>

              <div>
                <!--<span style="width:48%; text-align:left;  display: inline-block;"><a class="small-text" href="#">Forgot
                    password?</a></span>-->
                <span style="width: 100%; text-align: right;  display: inline-block;">
                  <input type="submit" value="Войти">
                </span>
              </div>

            </fieldset>
            <div class="clearfix"></div>
          </form>
          <div class="clearfix"></div>

        </div>
        <div v-if="error" class="login-error">
          {{ error }}<br>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="js">
import { ApiSys } from '../../services/api';
import MD5 from 'md5';
import { useDefaultStore } from '@/store';
import { loadDivisions } from "../../services/divisions";
import User from "../../services/user";
export default {
  name: 'Auth',

  data() {
    return {
      user: null,
      password: null,
      error: null
    };
  },
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  beforeCreate() {
    if (this.$route.query.token) {
      localStorage.token = this.$route.query.token;
      this.$router.push({ name: 'cam-listNew' });

      setTimeout(() => {
        //this.store.toggleLoader(false);
      }, 3000);
    }
  },

  methods: {

    login() {
      this.error = null;
      let options = {
        'user': this.user.trim(),
        'password': MD5(this.password)
      };
      //this.store.toggleLoader(true);
      ApiSys.Login(options).then(async (response) => {
        if (!response.error) {
          localStorage.token = response.data.token;
          await User.getCurrentUser();
          await loadDivisions();
          this.$router.push({ name: 'cam-listNew' });
          setTimeout(() => {
            //this.store.toggleLoader(false);
          }, 3000);

        } else {
          localStorage.token = '';
          this.store.setCurrentUser(null);
          this.error = response.error.message;
          //this.store.toggleLoader(false);
        }
      });
    },

    toCams() {
      this.$router.push({ name: 'cam-list' });
    }

  }
};
</script>

<style lang="stylus">
div.main {
  background: c-body-bg; /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, #3b3d4a 1%, #000000 100%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(1%, #3b3d4a), color-stop(100%, #000000)); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, #3b3d4a 1%, #000000 100%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover, #3b3d4a 1%, #000000 100%); /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover, #3b3d4a 1%, #000000 100%); /* IE10+ */
  background: radial-gradient(ellipse at center, #3b3d4a 1%, #000000 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#0264d6', endColorstr = '#1c2b5a', GradientType = 1 );
  height: calc(100vh);
  width: 100%;
}

[class*="fontawesome-"]:before {
  font-family: 'FontAwesome', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0 auto;

  &:before,
  &:after {
    box-sizing: border-box;
  }

}

a {
  color: #eee;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input {
  border: none;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  padding: 0;
  -webkit-appearance: none;
}

p {
  line-height: 1.5em;
}

/*
    .clearfix {
        *zoom: 1;

        &:before, &:after {
            content: ' ';
            display: table;
        }

        &:after {
            clear: both;
        }
    }*/

.container {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}

#login form {
  width: 250px;
}

#login, .logo {
  display: inline-block;
  width: 40%;
}

#login {
  border-right: 1px solid #fff;
  padding: 0 22px;
  width: 100%;
}

.logo {
  color: #fff;
  text-align: center;
  width:100%;
  margin-bottom:30px;

  .mdi {
    font-size: 46px;
  }
}

#login form span.mdi {
  background-color: #fff;
  border-radius: 3px 0 0 3px;
  color: #000;
  display: block;
  float: left;
  height: 50px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

#login form input {
  height: 50px;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;

}

#login form input[type="text"],
#login input[type="password"] {
  background-color: #fff;
  border-radius: 0 3px 3px 0;
  color: #000;
  margin-bottom: 1em;
  padding: 0 16px;
  width: 200px;

  &:focus {
    outline: none
  }
}

#login form input[type="submit"] {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #000000;
  color: #eee;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
  height: 30px;
}

#login form input[type="submit"]:hover {
  background-color: #57657f;
}

#login > p {
  text-align: center;
}

 > p span {
  padding-left: 5px;
}


#Auth .logo_img {
  display: block;
  width: 100%;
  height: 40px;
  background: url("../../assets/img/pervaya.png") no-repeat center;
  background-size: contain;
}


.login-error {
  width: 100%
  color: #b00000
  padding: 13px 22px;
  text-shadow: 5px 5px 2px #000;
}

.middle {
  display: flex;
  width: 300px;
  margin: 0 auto
  flex-wrap: wrap
}

</style>
